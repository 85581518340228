import React, { useMemo } from 'react';
import Lottie from 'react-lottie';
import logoAndFontData from './logo-and-font.json';
import onlyLogoData from './only-logo.json';

interface PrimeLogoProps {
  type?: 'onlyLogo' | 'logoAndFont';
  height?: number;
  width?: number;
}

const PrimeLogoAnimated = ({
  type = 'onlyLogo',
  height = type === 'logoAndFont' ? 150 : 100,
  width = type === 'logoAndFont' ? 150 : 100,
}: PrimeLogoProps) => {
  const animationData = type === 'logoAndFont' ? logoAndFontData : onlyLogoData;

  const options = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
      },
    }),
    [animationData],
  );

  return <Lottie options={options} height={height} width={width} />;
};

export default React.memo(PrimeLogoAnimated);
