import {
  generateButtonClass,
  type ButtonClassProps,
} from '@/app/_ui/buttons/generateButtonClass';
import Tooltip from '@/app/_ui/common/Tooltip';
import DynamicSvgIcon from '@/app/_ui/icons/DynamicSvgIcon';
import { PrimeLogoAnimated } from '@/app/_ui/primeLogoAnimated';
import { type IconType } from '@/app/_ui/types';
import {
  useRef,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
} from 'react';

export type AppButtonProps = ButtonClassProps & {
  text?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  data?: string;
  type?: 'button' | 'submit' | 'reset';
  Icon?: IconType;
  iconSize?: number;
  iconPlacement?: 'left' | 'right';
  children?: ReactNode;
  tooltip?: string | ReactElement;
};

const AppButton = ({
  text,
  onClick,
  isLoading,
  Icon,
  className,
  color,
  children,
  data,
  border,
  contentAlign,
  borderType = 'round',
  iconSize = 24,
  type = 'button',
  compact = false,
  variant = 'outlined',
  disabled = false,
  iconPlacement = 'right',
  padding = true,
  fullWidth = false,
  forceInline = false,
  isSelected = false,
  tooltip,
}: AppButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isDisabled =
    (disabled || (onClick == null && type !== 'submit') || isLoading) ?? false;

  const { containerClass, innerContainerClass } = generateButtonClass({
    border,
    borderType,
    compact,
    variant,
    disabled,
    color,
    padding,
    isSelected,
    fullWidth,
    forceInline,
    className,
    contentAlign,
  });

  const IconElement = Icon ? (
    typeof Icon === 'string' ? (
      <DynamicSvgIcon iconName={Icon} height={iconSize} width={iconSize} />
    ) : (
      <Icon
        className={`h-${Math.ceil(iconSize / 6)} w-${Math.ceil(iconSize / 6)}`}
      />
    )
  ) : null;

  const buttonContent = (
    <div className={innerContainerClass}>
      {iconPlacement === 'left' && IconElement}
      {children ? children : text}
      {iconPlacement === 'right' && IconElement}
    </div>
  );

  return (
    <button
      type={type}
      onClick={onClick}
      className={containerClass}
      disabled={isDisabled}
      data-data={data}
      ref={buttonRef}
    >
      {isLoading ? (
        <PrimeLogoAnimated type="onlyLogo" height={30} width={30} />
      ) : tooltip ? (
        <Tooltip content={tooltip}>{buttonContent}</Tooltip>
      ) : (
        buttonContent
      )}
    </button>
  );
};

export default AppButton;
