'use client';

import RcTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { type ReactElement } from 'react';

export type TooltipAnchor =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom';

interface TooltipProps {
  children: ReactElement;
  content?: ReactElement | string;
  anchor?: TooltipAnchor;
}

function Tooltip({ children, content, anchor = 'top' }: TooltipProps) {
  if (content === undefined) {
    return children;
  }

  return (
    <RcTooltip
      placement={anchor}
      overlay={content}
      mouseEnterDelay={0.1}
      mouseLeaveDelay={0.1}
      showArrow={false}
      trigger={['hover', 'focus']}
      overlayStyle={{
        maxWidth: '300px',
        width: 'auto',
        borderRadius: '6px',
        fontSize: '14px',
        zIndex: '2000',
      }}
      overlayInnerStyle={{
        border: 'none',
        color: '#ffffff',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      }}
    >
      {children}
    </RcTooltip>
  );
}

export default Tooltip;
